// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --green: #c62625;
  --gray: #bbbbbb;
  --white: #fff;
}

.subscribe-button {
  position: relative;
  outline: none;
  height: 40px;
  width: 100px;
  border-radius: 40px;
  background: var(--white);
  border: 2px solid var(--green);
  color: var(--green);
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.25s ease;
  text-align: center;
  letter-spacing: 1px;
}

.subscribe-button.loading {
  width: 40px;
  border-width: 3px;
  border-color: var(--gray);
  font-size: 0;
  border-left-color: var(--green);
  animation: rotating 2s 0.25s linear infinite;
}

.subscribe-button.success {
  width: 40px;
  font-size: 13px;
  color: var(--white);
  background: var(--green);
}

/* .subscribe-button:after {
  content: "Submit";
} */

#footer-btns {
  z-index: 1000;
}

.subscribe-button.loading:after {
  content: "";
}

.subscribe-button.success:after {
  font-family: "FontAwesome";
  content: "\\f00c"; /* Checkmark symbol */
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,wBAAwB;EACxB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,0BAA0B;EAC1B,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,+BAA+B;EAC/B,4CAA4C;AAC9C;;AAEA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;;GAEG;;AAEH;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB,EAAE,qBAAqB;AACzC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[":root {\r\n  --green: #c62625;\r\n  --gray: #bbbbbb;\r\n  --white: #fff;\r\n}\r\n\r\n.subscribe-button {\r\n  position: relative;\r\n  outline: none;\r\n  height: 40px;\r\n  width: 100px;\r\n  border-radius: 40px;\r\n  background: var(--white);\r\n  border: 2px solid var(--green);\r\n  color: var(--green);\r\n  font-size: 13px;\r\n  font-weight: bold;\r\n  cursor: pointer;\r\n  transition: all 0.25s ease;\r\n  text-align: center;\r\n  letter-spacing: 1px;\r\n}\r\n\r\n.subscribe-button.loading {\r\n  width: 40px;\r\n  border-width: 3px;\r\n  border-color: var(--gray);\r\n  font-size: 0;\r\n  border-left-color: var(--green);\r\n  animation: rotating 2s 0.25s linear infinite;\r\n}\r\n\r\n.subscribe-button.success {\r\n  width: 40px;\r\n  font-size: 13px;\r\n  color: var(--white);\r\n  background: var(--green);\r\n}\r\n\r\n/* .subscribe-button:after {\r\n  content: \"Submit\";\r\n} */\r\n\r\n#footer-btns {\r\n  z-index: 1000;\r\n}\r\n\r\n.subscribe-button.loading:after {\r\n  content: \"\";\r\n}\r\n\r\n.subscribe-button.success:after {\r\n  font-family: \"FontAwesome\";\r\n  content: \"\\f00c\"; /* Checkmark symbol */\r\n}\r\n\r\n@keyframes rotating {\r\n  from {\r\n    transform: rotate(0deg);\r\n  }\r\n  to {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
