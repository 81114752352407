import toast from "react-hot-toast";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const AppStore = create((set, get) => ({
  bUrl: "https://back.orima.world",
  // bUrl: "http://localhost:5005",
  isMobile: false,
  cartCount: 0,
  page: "home",
  isCartOpen: false,
  isMenuOpen: false,
  isCareOpen: false,

  //
  layoutContainerRef: null,

  addCartCount: () => {
    set((state) => ({
      cartCount: state.cartCount < 50 ? state.cartCount + 1 : 50,
    }));
  },
  decreaseCartCount: () => {
    set((state) => ({
      cartCount: state.cartCount > 0 ? state.cartCount - 1 : 0,
    }));
  },
  toggleCart: () => {
    set((state) => ({ isCartOpen: !state.isCartOpen }));
  },
  toggleMenu: () => {
    set((state) => ({ isMenuOpen: !state.isMenuOpen }));
  },
  toggleCare: () => {
    set((state) => ({ isCareOpen: !state.isCareOpen }));
  },
  scrollToView: (viewId) => {
    if (get().layoutContainerRef && viewId) {
      const targetElement = document.getElementById(
        viewId ? viewId : "landing"
      );
      if (targetElement) {
        set(() => ({ page: viewId == "landing" ? "home" : viewId }));
        const offsetLeft = targetElement.offsetLeft;
        console.log(offsetLeft, get().layoutContainerRef.scrollLeft);
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      }
    }
  },
  notify: (msg) => {
    const isMobile = get().isMobile;

    toast(msg, {
      position: isMobile ? "top-center" : "bottom-right",
      duration: isMobile ? 2500 : 2000,
    });
  },
}));
