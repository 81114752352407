import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import './Footer.css'

export default function CheckoutForm({ setCartPage }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus('loading')
    
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
      
  
      // timeout for 2 seconds
      // wait for 2 seconds before confirming the payment
  
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          // return_url: "https://orima.world/payment_success",
          return_url:'https://orima.world/',
        },
        redirect: 'if_required', // Handle redirect manually
      });
  
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      if (!error) {
        setTimeout(() => {
          setStatus('')
          setIsLoading(false);
          setMessage("Payment succeeded!");
        }, 2000);
      }


      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } catch (error) {
      
    }
    setIsLoading(false);
    setStatus('')
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className=" h-full w-full relative flex flex-col items-center gap-3"
    >
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className={`absolute bottom-14 max-sm:bottom-6 w-[45%] py-2 rounded-[49px] text-white subscribe-button ${status != 'loading' ? 'bg-primary-red' : status }`}
        // onClick={() => setCartPage(0)}
      >
        <h6 id="button-text" className="">
          {isLoading ? "Processing ..." : "Purchase"}
        </h6>
        
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message" className="text-center px-2">{message}</div>}
    </form>
  );
}
