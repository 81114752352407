import aboutVid2 from '../media/videos/about.mp4'
import homeVid2 from '../media/videos/home.mp4'

export const redLogo = "/Logo/logo-red.png";

export const primaryBackground = "#fffff";
// export const primaryContentBackround = "#ee2d2b";
export const primaryContentBackround = "#C62625";

export const arrowGreyPNG = "/icons/arrow-grey.png";


export const homeVid = require("../media/videos/home.mp4");
export const aboutVid = require("../media/videos/about.mp4");

export {aboutVid2, homeVid2}

export const mrAlex = "/images/Alex.webp";
export const mrsMelissa = "/images/Melissa.webp";

export const beanComb = "/images/bean.jpg";
export const sampleComb = "/images/sample.png";
export const simpleComb = "/images/simple-bean.png";

export const sliderImages = [
  {
    id: 10,
    src: "/images/slider/1.webp",
    alt: "slider1",
  },
  {
    id: 20,
    src: "/images/slider/2.webp",
    alt: "slider2",
  },
  {
    id: 30,
    src: "/images/slider/3.webp",
    alt: "slider3",
  },
  {
    id: 40,
    src: "/images/slider/4.webp",
    alt: "slider4",
  },
];
