// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  font-weight: 500;
}

.menu {
  filter: drop-shadow(1px 1px 1px #d6cbcb7b);
  width: 100%;
  /* position: block; */
  /* width: 300px; */
  /* background-color: gray; */
}

ul {
  display: flex;
  flex-direction: column;
  background: var(--accent);
  background: var(--gray);
  list-style-type: disc !important;
  z-index: 1000;
}

li {
  display: block;
  transform-origin: -20px -20px 10%;
  z-index: 1000 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Menu.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,0CAA0C;EAC1C,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,gCAAgC;EAChC,aAAa;AACf;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,wBAAwB;AAC1B","sourcesContent":["h2 {\r\n  font-weight: 500;\r\n}\r\n\r\n.menu {\r\n  filter: drop-shadow(1px 1px 1px #d6cbcb7b);\r\n  width: 100%;\r\n  /* position: block; */\r\n  /* width: 300px; */\r\n  /* background-color: gray; */\r\n}\r\n\r\nul {\r\n  display: flex;\r\n  flex-direction: column;\r\n  background: var(--accent);\r\n  background: var(--gray);\r\n  list-style-type: disc !important;\r\n  z-index: 1000;\r\n}\r\n\r\nli {\r\n  display: block;\r\n  transform-origin: -20px -20px 10%;\r\n  z-index: 1000 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
