import React, { useState } from "react";
import bcrypt from "bcryptjs";

export default function AdminRoute({ children }) {
  // We'll store whether the user is authenticated
  const [authenticated, setAuthenticated] = useState(false);
  const [inputPassword, setInputPassword] = useState("");

  // This is our hashed admin password from .env
  // e.g., REACT_APP_ADMIN_PASS_HASH = "$2a$10$..."
  // const ADMIN_PASS_HASH = process.env.REACT_APP_ADMIN_PASS_HASH;

  const handleSubmit = (e) => {
    e.preventDefault();
    // Compare the entered password with the hashed password
    // const isMatch = bcrypt.compareSync(inputPassword, ADMIN_PASS_HASH);
    const isMatch = inputPassword == 'Orima!'

    if (isMatch) {
      setAuthenticated(true);
    } else {
      alert("Invalid password. Access denied.");
      setInputPassword("");
    }
  };

  if (!authenticated) {
    return (
      <div className="w-screen h-screen flex items-center justify-center bg-primary-red">
        <div className="bg-white p-6 md:p-8 lg:p-10 rounded-[49px] flex flex-col items-center w-[90%] max-w-xl text-center">
          <h2 className="text-2xl font-semibold mb-4">Orimees Access</h2>
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
            {/* <label htmlFor="passwordInput" className="text-lg font-medium">
              Enter Admin Password:
            </label> */}
            <input
              id="passwordInput"
              type="password"
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
              required
              className="border border-gray-300 rounded py-2 px-4 text-center text-lg focus:outline-none"
              placeholder="••••••••"
            />
            <button
              type="submit"
              className="bg-primary-red text-white rounded py-2 text-lg font-medium hover:bg-red-600"
            >
              Unlock
            </button>
          </form>
        </div>
      </div>
    );
  }

  // If authenticated, render the admin content (children)
  return <>{children}</>;
}
