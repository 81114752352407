// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Slider.css */
.slider-container {
  /* position: relative; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.dots {
  /* width: 100px;
  height: 20px; */
  position: absolute;
  bottom: 20px;
  left: 49%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 0;
}

.dot {
  width: 15px;
  height: 15px;
  background-color: rgba(180, 180, 180, 0.8);
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #c62625;
}

/* Media query for dots on mobile */
@media (max-width: 768px) {
  .dots {
    right: 10px;
    left: 95%;
    top: 39%;
    flex-direction: column;
  }

  .dot {
    width: 10px;
    height: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Slider.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,wBAAwB;EACxB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE;iBACe;EACf,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,aAAa;EACb,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,0CAA0C;EAC1C,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,mCAAmC;AACnC;EACE;IACE,WAAW;IACX,SAAS;IACT,QAAQ;IACR,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":["/* src/Slider.css */\n.slider-container {\n  /* position: relative; */\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n\n.slider-background {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\n.dots {\n  /* width: 100px;\n  height: 20px; */\n  position: absolute;\n  bottom: 20px;\n  left: 49%;\n  transform: translateX(-50%);\n  display: flex;\n  gap: 10px;\n  z-index: 0;\n}\n\n.dot {\n  width: 15px;\n  height: 15px;\n  background-color: rgba(180, 180, 180, 0.8);\n  border-radius: 50%;\n  cursor: pointer;\n}\n\n.dot.active {\n  background-color: #c62625;\n}\n\n/* Media query for dots on mobile */\n@media (max-width: 768px) {\n  .dots {\n    right: 10px;\n    left: 95%;\n    top: 39%;\n    flex-direction: column;\n  }\n\n  .dot {\n    width: 10px;\n    height: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
