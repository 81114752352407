// src/pages/NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar'; // Import Navbar if used
import Footer from '../components/Footer'; // Import Footer if used

const NotFound = () => {
  return (
    <>
      <Navbar />
      <section
        className="flex flex-col items-center justify-center min-h-[calc(100vh_-_75px)] bg-white text-primary-red p-4"
      >
        <h1 className="text-6xl font-bold mb-4">404</h1>
        <h2 className="text-2xl font-bold mb-2">Page Not Found</h2>
        <p className="text-lg mb-6 text-center max-w-md">
          The page you're looking for doesn't exist. It might have been moved or deleted.
        </p>
        <Link
          to="/"
          className="border-2 border-primary-red text-primary-red rounded-[49px] px-6 py-2 hover:bg-primary-red hover:text-white transition"
        >
          Go Back Home
        </Link>
      </section>
      <Footer />
    </>
  );
};

export default NotFound;
