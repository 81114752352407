// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/VideoComponent.css */
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* padding-top: 56.25%; 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  overflow: hidden;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Video.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,qEAAqE;EACrE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,iBAAiB,EAAE,kDAAkD;AACvE","sourcesContent":["/* src/VideoComponent.css */\r\n.video-container {\r\n  position: relative;\r\n  width: 100%;\r\n  height: 100%;\r\n  /* padding-top: 56.25%; 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */\r\n  overflow: hidden;\r\n}\r\n\r\n.video {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  object-fit: cover; /* Maintain aspect ratio and cover the container */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
