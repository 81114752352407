// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Model.css */
.canvas-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fallback {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #fff;
}

@media (max-width: 768px) {
  .canvas-container {
  }

  .fallback {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .canvas-container {
  }

  .fallback {
    font-size: 0.8rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Model.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE;EACA;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;EACA;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["/* src/Model.css */\r\n.canvas-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.fallback {\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  font-size: 1.5rem;\r\n  color: #fff;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .canvas-container {\r\n  }\r\n\r\n  .fallback {\r\n    font-size: 1rem;\r\n  }\r\n}\r\n\r\n@media (max-width: 480px) {\r\n  .canvas-container {\r\n  }\r\n\r\n  .fallback {\r\n    font-size: 0.8rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
