// src/components/Navbar.js

import React from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { redLogo } from "../constants/palette";
import { AppStore } from "../constants/Stores";
import Menu from "./Menu";
import "./nav-motion.css";

const Navbar = () => {
  const location = useLocation();

  // Accessing Zustand store using the recommended pattern
  const cartCount = AppStore((state) => state.cartCount);
  const toggleCart = AppStore((state) => state.toggleCart);
  const isCartOpen = AppStore((state) => state.isCartOpen);
  const toggleCare = AppStore((state) => state.toggleCare);
  const isCareOpen = AppStore((state) => state.isCareOpen);
  const isMenuOpen = AppStore((state) => state.isMenuOpen);
  const currentPath = location.pathname;

  const navItems = [
    { index: 1, name: "Home", href: "/" },
    { index: 2, name: "Shop", href: "/shop" },
    { index: 3, name: "About", href: "/about" },
  ];

  return (
    <header className="fixed top-0 left-0 w-screen z-[90]">
      <nav className="absolute w-full h-[60px] flex justify-between items-center max-sm:px-5 px-7 pt-2 text-primary-red">
        <div className="navigation text-primary-red font-bold relative flex gap-6">
          <div className="nav-container text-primary-red font-bold hidden md:flex flex-1 min-w-[300px]">
            {navItems.map((navItem) => (
              <NavLink
                to={navItem.href}
                key={navItem.index}
                className={({ isActive }) =>
                  `font-bold text-md px-3 min-w-[30px] ${
                    isActive
                      ? "border-2 border-primary-red text-primary-red p-1 rounded-[49px]"
                      : "hover:cursor-pointer"
                  }`
                }
              >
                {navItem.name}
              </NavLink>
            ))}
          </div>
          <Menu isNavbar={true} isopp={isMenuOpen} />
        </div>
        <div className="logo border-red-300 pb-1">
          <Link to="/">
            <img src={redLogo} alt="logo" className="h-8" />
          </Link>
        </div>
        <div className="functions">
          <ul className="flex gap-6 text-primary-red text-lg font-bold flex-row items-center w-full bg-transparent">
            <li className="max-sm:hidden pb-[2px] min-w-12 text-center">
              <button
                className={`px-3 p-1 ${
                  isCareOpen
                    ? "outline bg-primary-red border-primary-red border-2 text-white  rounded-[49px]"
                    : "hover:cursor-pointer"
                }`}
                onClick={toggleCare}
              >
                Care
              </button>
            </li>
            <li>
              <button
                className={`border-2 border-primary-red rounded-full py-[3px] px-[13px] text-sm flex justify-center items-center w-[28px] ${
                  isCartOpen ? "bg-primary-red text-white" : ""
                }`}
                onClick={toggleCart}
              >
                {cartCount}
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
