import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { AppStore } from "../constants/Stores";

const AdminPanel = () => {
  const [orders, setOrders] = useState([]);

  // Fetch orders on mount
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${AppStore.getState().bUrl}/api/orders`);
        if (!response.ok) {
          throw new Error("Failed to fetch orders");
        }
        const data = await response.json();
        if (data.success) {
          setOrders(data.orders);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchOrders();
  }, []);

  // Delete an order
  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this order?")) return;

    try {
      const response = await fetch(`/api/orders/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete order");
      }
      const data = await response.json();
      if (data.success) {
        setOrders((prev) => prev.filter((order) => order.id !== id));
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Mark an order as complete
  const handleComplete = async (id) => {
    try {
      const response = await fetch(`/api/orders/${id}/complete`, {
        method: "PATCH",
      });
      if (!response.ok) {
        throw new Error("Failed to update order status");
      }
      const data = await response.json();
      if (data.success) {
        setOrders((prev) =>
          prev.map((order) =>
            order.id === id ? { ...order, order_status: "complete" } : order
          )
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <Navbar />
      <div className="flex-1 mt-20 bg-primary-red bg-opacity-95 rounded-t-[49px] flex flex-col items-center p-5">
        <h2 className="text-2xl text-white font-bold mb-6">Admin Panel</h2>

        <div className="w-full max-w-7xl overflow-x-auto">
          {orders.length === 0 ? (
            <div className="text-center text-white">No orders found.</div>
          ) : (
              <table className="min-w-full bg-white rounded-lg overflow-hidden">
              <thead className="bg-primary-red text-white">
                <tr>
                  <th className="py-3 px-4 text-left">ID</th>
                  <th className="py-3 px-4 text-left">Ordered At</th>
                  <th className="py-3 px-4 text-left">Product Name</th>
                  <th className="py-3 px-4 text-left">Quantity</th>
                  <th className="py-3 px-4 text-left">Total</th>
                  <th className="py-3 px-4 text-left">Delivery Name</th>
                  <th className="py-3 px-4 text-left">Email</th>
                  <th className="py-3 px-4 text-left">Status</th>
                  <th className="py-3 px-4 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                    <tr key={order.id} className="border-b border-gray-200">
                      {/* {console.log(order)} */}
                    <td className="py-3 px-4">{order.id}</td>
                    <td className="py-3 px-4">
                      {/* Show created_at or fallback if null */}
                      {order.created_at ? new Date(order.created_at).toLocaleString() : "N/A"}
                    </td>
                    <td className="py-3 px-4">{order.product_name || "N/A"}</td>
                    <td className="py-3 px-4">{order.quantity || 1}</td>
                    <td className="py-3 px-4">${order.total?.toFixed(2) || "0.00"}</td>
                    <td className="py-3 px-4">
                      {order.delivery_first_name} {order.delivery_last_name}
                    </td>
                    <td className="py-3 px-4">{order.delivery_email}</td>
                    <td className="py-3 px-4 capitalize">
                      {order.order_status || "new"}
                    </td>
                    <td className="py-3 px-4">
                      <button
                        onClick={() => handleComplete(order.id)}
                        className="bg-green-500 hover:bg-green-600 text-white py-1 px-3 rounded mr-2"
                      >
                        Complete
                      </button>
                      <button
                        onClick={() => handleDelete(order.id)}
                        className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
