// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-content-desktop {
  display: flex;
  padding: 20px;
  border-radius: 49px;
  max-width: 500px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content-mobile {
  padding: 20px;
  align-self: flex-start;
  margin-top: 4.5rem;
  border-radius: 49px;
  width: 94%;
  min-height: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/Care.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,sBAAsB;EACtB,uBAAuB;EACvB,iBAAiB;EACjB,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;EACV,iBAAiB;EACjB,yCAAyC;AAC3C","sourcesContent":[".backdrop {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  /* background: rgba(0, 0, 0, 0.5); */\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 1001;\r\n}\r\n\r\n.modal-content-desktop {\r\n  display: flex;\r\n  padding: 20px;\r\n  border-radius: 49px;\r\n  max-width: 500px;\r\n  width: 100%;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  min-height: 500px;\r\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.modal-content-mobile {\r\n  padding: 20px;\r\n  align-self: flex-start;\r\n  margin-top: 4.5rem;\r\n  border-radius: 49px;\r\n  width: 94%;\r\n  min-height: 500px;\r\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
