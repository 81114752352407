// src/Model.js
import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Html,
  OrbitControls,
  Preload,
  useGLTF,
  useProgress,
} from "@react-three/drei";
import "./Model.css";
import { AppStore } from "../constants/Stores";

const Model = () => {
  const { scene } = useGLTF("/models/comb.glb");

  return <primitive object={scene} scale={[1, 1, 1]} />;
};

function Loader() {
  const { progress } = useProgress();
  return <Html className="model-loader self-center place-self-center"></Html>;
}

const CombModel = () => {
  const isMobile = AppStore((state) => state.isMobile);

  return (
    <Canvas
      className="canvas-container"
      frameloop="demand"
      camera={{ position: [-4, 0, -3], fov: 1 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      {/* <pointLight position={[0, 0, 0]} intensity={1} color="red" /> */}
      <rectAreaLight
        width={2.5}
        height={2.5}
        color={"#fff"}
        intensity={45}
        position={[0, 0, 5]}
        penumbra={1}
        // castShadow
      />
      <OrbitControls
        enableZoom={false}
        enablePan={false}
        enableDamping={false}
        dampingFactor={0.05}
        maxPolarAngle={Math.PI * 2}
        minPolarAngle={Math.PI / 4}
        autoRotate={true}
        autoRotateSpeed={isMobile ? 8.5 : 2.5}
        // target-y={0}
        // target-x={0}
        target={[0, 0, -0.006]}
        // minDistance={1}
        // maxDistance={6}
      />
      <Suspense fallback={<Loader />}>
        <mesh rotation={[3.1442, 0, -4.71]} position={[-0.0065, 0.0075, 0]}>
          <hemisphereLight intensity={1} groundColor="white" />

          <rectAreaLight
            width={3}
            height={3}
            color={"#fff"}
            intensity={29}
            position={[0, 0, 5]}
            penumbra={1}
          />
          <group dispose={null}>
            {/* <primitive object={gltf.scene} scale={1} /> */}
            <Model />
          </group>
        </mesh>
      </Suspense>
      <Preload all />
    </Canvas>
  );
};

export default CombModel;
