import { useState, useEffect } from "react";
import { useAnimate, stagger, motion } from "framer-motion";
import "./Menu.css";
import { primaryContentBackround } from "../constants/palette";
import { Link } from "react-scroll";
import { AppStore } from "../constants/Stores";
import { useLocation, useNavigate } from "react-router-dom";
//

const staggerMenuItems = stagger(0.1, { startDelay: 0.15 });

function useMenuAnimation(isOpen) {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(".arrow", { rotate: isOpen ? 0 : 0 }, { duration: 0.2 });

    animate(
      "ul",
      {
        clipPath: isOpen
          ? "inset(0% 0% 0% 0% round 10px)"
          : "inset(10% 50% 90% 50% round 10px)",
      },
      {
        type: "spring",
        bounce: 0,
        duration: 0.5,
      }
    );

    // animate(
    //   "li",
    //   isOpen
    //     ? { opacity: 1, scale: 1, filter: "blur(0px)" }
    //     : { opacity: 1, scale: 0.3, filter: "blur(0px)" },
    //   {
    //     duration: 0.2,
    //     delay: isOpen ? staggerMenuItems : 0,
    //   }
    // );
  }, [isOpen]);

  return scope;
}

const Menu = ({ isNavbar = true, isopp }) => {
  const [isOpen, setIsOpen] = useState(false);
  const scope = useMenuAnimation(isopp);
  const navigate = useNavigate();
  const location = useLocation();
  let currentPath = location.pathname;
  if (currentPath === "/") {
    currentPath = "/home";
  }
  const isMenuOpen = AppStore((state) => state.isMenuOpen);
  const toggleMenu = AppStore((state) => state.toggleMenu);
  const toggleCare = AppStore((state) => state.toggleCare);
  const scrollToView = AppStore((state) => state.scrollToView);

  // handle menu toggle
  useEffect(() => {
    AppStore.setState({ isMenuOpen: isOpen });
  }, [isOpen]);

  return (
    <nav className="md:hidden z-[90] justify-center flex " ref={scope}>
      <div className=" arrow w-full flex justify-between bg-transparent ">
        {isNavbar ? (
          <div className="flex h-10 items-center justify-between w-[35px] ">
            {isMenuOpen ? (
              <svg
                width="33px"
                height="24px"
                viewBox="0 0 1024 1024"
                onClick={toggleMenu}
              >
                <path
                  fill="#c62625"
                  d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                />
              </svg>
            ) : (
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="#c62625"
                onClick={toggleMenu}
              >
                <path
                  d="M5 7H19"
                  stroke="#c62625"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 12L19 12"
                  stroke="#c62625"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 17L19 17"
                  stroke="#c62625"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        ) : null}
      </div>

      <div className="w-[93.5vw] mx-0 flex justify-center !p-0 !m-0 top-[59px] left-0 absolute !ml-[-6px]">
        <ul
          style={{
            pointerEvents: isopp ? "auto" : "none",
            clipPath: "inset(10% 50% 90% 50% round 10px)",
          }}
          id="mob-menu"
          className="min-w-full grid bg-primary-red py-4 gap-1 rounded-[43px] z-[1000] absolute"
        >
          {["Home", "Shop", "About", "Care"].map((link, index) => (
            <li key={index} className="flex justify-center z-[100] text-md">
              <Link
                to={"/"}
                smooth={true}
                duration={500}
                onClick={() =>
                  link == "Care"
                    ? toggleCare()
                    : navigate(link === "Home" ? "/" : "/" + link.toLowerCase())
                }
              >
                <button
                  className={`px-10 py-1 z-100 ${
                    currentPath == "/" + link.toLowerCase()
                      ? "text-white"
                      : "text-white-400"
                  }`}
                >
                  {link}
                </button>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Menu;
