import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./Footer.css";
import { AppStore } from "../constants/Stores";

export default function CheckoutForm({ setCartPage, formData, paymentIntent }) {
  const stripe = useStripe();
  const elements = useElements();

  console.log("formData", formData);

  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("formData", formData);
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus("loading");

    if (!stripe || !elements) {
      return;
    }

    try {
      console.log("Requesting payment intent");
      // Pre-check backend health

      const healthCheck = await fetch(`${AppStore.getState().bUrl}/health`, {
        method: "GET",
      }).then((res) => res.json());

      if (!healthCheck.ok) {
        throw new Error("Backend is unreachable. Please try again later.");
      }
      console.log("Health check passed");

      const { client_secret: clientSecret, id: paymentIntentId } =
        paymentIntent;
      console.log(clientSecret, paymentIntentId);

      if (!clientSecret) {
        throw new Error("Failed to create payment intent.");
      }

      // Confirm payment with Stripe
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: { return_url: "https://orima.world/" },
        redirect: "if_required",
      });

      if (error) {
        throw new Error(error.message);
      }

      console.log("formData before send", formData);

      // Send order creation request to backend
      const response = await fetch(`${AppStore.getState().bUrl}/create-order`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...formData, paymentIntentId }),
      });

      const result = await response.json();
      if (!result.success) {
        throw new Error("Order creation failed.");
      }

      setMessage("Payment & order succeeded!");
      setCartPage(3);
    } catch (err) {
      setMessage(`Error: ${err.message}`);
    } finally {
      setIsLoading(false);
      setStatus("");
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className=" h-full w-full relative flex flex-col items-center gap-3"
    >
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className={`absolute bottom-14 max-sm:bottom-6 w-[45%] py-2 rounded-[49px] text-white subscribe-button ${
          status != "loading" ? "bg-primary-red" : status
        }`}
        // onClick={() => setCartPage(0)}
      >
        <h6 id="button-text" className="font-xl">
          {isLoading ? "Processing ..." : "PURCHASE"}
        </h6>
      </button>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="text-center px-2">
          {message}
        </div>
      )}
    </form>
  );
}
