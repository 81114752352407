import Navbar from "./components/Navbar";
import Layout from "./pages/Layout";
import { Toaster } from "react-hot-toast";

function App() {
  const metaThemeColor = document.querySelector('meta[name="theme-color"]');
  metaThemeColor.setAttribute("content", "#ffffff");
  return (
    <main className="">
      <Toaster
        containerStyle={{
          top: 0,
          right: 0,
        }}
        containerClassName="toast-notif"
        toastOptions={{
          className: "",
          style: {
            right: "0 !important",
            bottom: "100px !important",
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        }}
      />
      <Navbar />
      <Layout />
    </main>
  );
}

export default App;
