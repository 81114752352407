import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Care.css";
import { AppStore } from "../constants/Stores";

const CareModal = ({ isOpen = true, setIsOpen }) => {
  const isMobile = AppStore((state) => state.isMobile);
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="backdrop"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={handleClose}
        >
          <motion.div
            className={`bg-primary-red ${
              !isMobile ? "modal-content-desktop" : "modal-content-mobile"
            } `}
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
            onClick={(e) => e.stopPropagation()} // Prevent click on modal from closing it
          >
            <div className="flex flex-col h-full justify-center items-center text-white text-center gap-5">
              <h3 className="text-bold text-2xl ">Orima Combs Care Guide:</h3>
              <p>
                1. Keep It Cool: Direct sunlight and extreme temperatures can
                damage your comb.
              </p>
              <p>
                2. Subtle Cleansing: Indulge your comb with a cleansing ritual.
                A little mild soap and lukewarm water preserves its performance.
              </p>
              <p>
                3. Chemical Exclusivity: Your cellulose acetate combs are part
                of a refined narrative. Care for them as an extension of your
                taste while you add more beauty to your everyday routine.
              </p>

              <div className="w-full flex justify-center items-center py-2 mt-4 text-white text-xl">
                <button onClick={handleClose}>
                  <svg width="30px" height="22px" viewBox="0 0 1024 1024">
                    <path
                      fill="white"
                      d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CareModal;
