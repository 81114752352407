import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AppStore } from "../constants/Stores";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { simpleComb } from "../constants/palette";

const stripePromise = loadStripe(
  // process.env.REACT_APP_STRIPE_TEST
  process.env.REACT_APP_STRIPE_LIVE
);

const Cart = () => {
  const billingAddressRef = useRef();
  const deliveryAddressRef = useRef();
  const cartCount = AppStore((state) => state.cartCount);
  const isCartOpen = AppStore((state) => state.isCartOpen);
  const increaseCart = AppStore((state) => state.addCartCount);
  const decreaseCart = AppStore((state) => state.decreaseCartCount);
  const [finalFormData, setFinalFormData] = useState(null);
  const [addressesSame, setAddressesSame] = useState(true);
  const [cartPage, setCartPage] = useState(0);

  const toggleCart = AppStore((state) => state.toggleCart);

  const handleCompleteOrder = () => {
    setCartPage(0);
    toggleCart();
  };

  const [paymentIntent, setPaymentIntent] = useState("");

  const clientSecret = paymentIntent.client_secret;

  const totalPrice = cartCount > 0 ? cartCount * 40 + 7 : 0;

  useEffect(() => {
    try {
      // Create PaymentIntent as soon as the page loads
      console.log("Fetching Payment");
      fetch(`${AppStore.getState().bUrl}/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amount: cartCount,
        }),
      })
        .then((res) => res.json())
        .then((data) => setPaymentIntent(data));
    } catch (error) {}
  }, [cartCount]);

  const appearance = {
    theme: "minimal",
    labels: "floating",
    variables: {
      fontFamily: "Sohne, system-ui, sans-serif",
      fontWeightNormal: "500",
      borderRadius: "8px",
      colorBackground: "white",
      colorPrimary: "#C62625",
      accessibleColorOnColorPrimary: "#1A1B25",
      colorText: "#C62625",
      colorTextSecondary: "#C62625",
      colorDanger: "#C62625",
      // colorTextPlaceholder: "#ABB2BF",
      tabIconColor: "#C62625",
      logoColor: "dark",
    },
    rules: {
      ".Input": {
        backgroundColor: "white",
        border: "1px solid var(--colorPrimary)",
        // fontSize: "14px",
      },
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  // const handles

  function PreCheckoutForm() {
    const deliveryAddressRef = useRef(null);
    const billingAddressRef = useRef(null);

    // State to track if the billing address is the same as the delivery address
    const [addressesSame, setAddressesSame] = useState(true);

    // Consolidated form data
    const [formData, setFormData] = useState({
      // delivery: {
      //   firstName: "FirstName",
      //   lastName: "LastName",
      //   address: "this is the address",
      //   apartment: "2",
      //   city: "Los Angeles",
      //   state: "CA",
      //   zipCode: "91311",
      //   email: "mintegaro@gmail.com",
      //   phoneNumber: "9999999999",
      // },
      delivery: {
        firstName: "",
        lastName: "",
        address: "",
        apartment: "",
        city: "",
        state: "",
        zipCode: "",
        email: "",
        phoneNumber: "",
      },
      billing: {
        firstName: "",
        lastName: "",
        address: "",
        apartment: "",
        city: "",
        state: "",
        zipCode: "",
      },
    });

    // Toggle for billing address
    const handleToggleSameAddress = (e) => {
      e.preventDefault();
      setAddressesSame((prev) => !prev);
    };

    // Unified onChange handler
    const handleChange = (section, field, value) => {
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value,
        },
      }));
    };

    // If addresses are the same, copy delivery into billing

    // Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
      let finalData = addressesSame
        ? {
            ...formData,
            billing: { ...formData.delivery },
          }
        : formData;

      finalData = {
        ...finalData,
        quantity: cartCount,
        productName: "BeanComb",
        total: cartCount * 40 + 7,
      };

      console.log("Data to submit:", finalData);

      setFinalFormData(finalData);
      setCartPage(2);
    };

    return (
      <form onSubmit={handleSubmit} className="justify-center flex">
        <div className="flex flex-col items-center">
          <h3 className="mb-7">Delivery Address</h3>

          {/* Delivery: First & Last Name */}
          <div className="flex flex-row w-11/12 gap-2" ref={deliveryAddressRef}>
            <input
              type="text"
              className="bg-white h-[65px] w-7/12 rounded-lg outline-none border border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
              placeholder="First name"
              required
              pattern="^[A-Za-z]+$"
              value={formData.delivery.firstName}
              onChange={(e) =>
                handleChange("delivery", "firstName", e.target.value)
              }
            />
            <input
              type="text"
              className="bg-white h-[65px] w-7/12 rounded-lg outline-none border border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
              placeholder="Last name"
              required
              pattern="^[A-Za-z]+$"
              value={formData.delivery.lastName}
              onChange={(e) =>
                handleChange("delivery", "lastName", e.target.value)
              }
            />
          </div>

          {/* Delivery: Address & Apartment */}
          <div className="flex flex-col w-full items-center">
            <input
              type="text"
              className="bg-white h-[65px] w-11/12 rounded-lg outline-none border mt-3 border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
              placeholder="Address"
              required
              value={formData.delivery.address}
              onChange={(e) =>
                handleChange("delivery", "address", e.target.value)
              }
            />
            <input
              type="text"
              className="bg-white h-[65px] w-11/12 rounded-lg outline-none border mt-3 border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
              placeholder="Apartment, suite, etc. (optional)"
              value={formData.delivery.apartment}
              onChange={(e) =>
                handleChange("delivery", "apartment", e.target.value)
              }
            />
          </div>

          {/* Delivery: City, State, ZIP */}
          <div className="flex flex-row w-11/12 gap-2 mt-3">
            <input
              type="text"
              className="bg-white h-[65px] w-5/12 rounded-lg outline-none border border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
              placeholder="City"
              required
              pattern="^[A-Za-z]+(?:[\s-][A-Za-z]+)*$"
              value={formData.delivery.city}
              onChange={(e) => handleChange("delivery", "city", e.target.value)}
            />
            <input
              type="text"
              className="bg-white h-[65px] w-5/12 rounded-lg outline-none border border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
              placeholder="State"
              required
              maxLength={2}
              pattern="^[A-Za-z]{2}$"
              value={formData.delivery.state}
              onChange={(e) =>
                handleChange("delivery", "state", e.target.value)
              }
            />
            <input
              type="text"
              className="bg-white h-[65px] w-5/12 rounded-lg outline-none border border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
              placeholder="ZIP Code"
              required
              pattern="^\d{5}(-\d{4})?$"
              value={formData.delivery.zipCode}
              onChange={(e) =>
                handleChange("delivery", "zipCode", e.target.value)
              }
            />
          </div>

          {/* Delivery: Email & Phone */}
          <input
            type="email"
            className="bg-white h-[65px] w-11/12 rounded-lg outline-none border mt-3 border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
            placeholder="Email"
            required
            value={formData.delivery.email}
            onChange={(e) => handleChange("delivery", "email", e.target.value)}
          />
          <input
            type="tel"
            className="bg-white h-[65px] w-11/12 rounded-lg outline-none border mt-3 border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
            placeholder="Phone number"
            required
            pattern="^\+?[0-9\s\-().]{7,}$"
            value={formData.delivery.phoneNumber}
            onChange={(e) =>
              handleChange("delivery", "phoneNumber", e.target.value)
            }
          />

          {/* Toggle for Billing Address */}
          <div className="w-full justify-center mt-8 flex-wrap flex items-center flex-row px-1 gap-2">
            <button
              className={`w-4 h-4 rounded-[49px] border border-primary-red hover:cursor-pointer ${
                addressesSame ? "bg-primary-red" : "bg-white"
              }`}
              onClick={handleToggleSameAddress}
              type="button"
            />
            <h4 className="text-sm">Billing address is same as delivery.</h4>
          </div>

          {/* Billing Address Section */}
          <div
            className={`w-full flex flex-col items-center mt-6 justify-center transition-opacity duration-200 ${
              addressesSame ? "opacity-0 pointer-events-none" : "opacity-100"
            }`}
            ref={billingAddressRef}
          >
            {!addressesSame && (
              <>
                <h3 className="mb-7 text-center">Billing Address</h3>
                <div className="flex flex-row w-11/12 gap-2">
                  <input
                    type="text"
                    className="bg-white h-[65px] w-7/12 rounded-lg outline-none border border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
                    placeholder="First name"
                    required
                    pattern="^[A-Za-z]+$"
                    value={formData.billing.firstName}
                    onChange={(e) =>
                      handleChange("billing", "firstName", e.target.value)
                    }
                  />
                  <input
                    type="text"
                    className="bg-white h-[65px] w-7/12 rounded-lg outline-none border border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
                    placeholder="Last name"
                    required
                    pattern="^[A-Za-z]+$"
                    value={formData.billing.lastName}
                    onChange={(e) =>
                      handleChange("billing", "lastName", e.target.value)
                    }
                  />
                </div>
                <input
                  type="text"
                  className="bg-white h-[65px] w-11/12 rounded-lg outline-none border mt-3 border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
                  placeholder="Address"
                  required
                  value={formData.billing.address}
                  onChange={(e) =>
                    handleChange("billing", "address", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="bg-white h-[65px] w-11/12 rounded-lg outline-none border mt-3 border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
                  placeholder="Apartment, suite, etc. (optional)"
                  value={formData.billing.apartment}
                  onChange={(e) =>
                    handleChange("billing", "apartment", e.target.value)
                  }
                />
                <div className="flex flex-row w-11/12 gap-2 mt-3">
                  <input
                    type="text"
                    className="bg-white h-[65px] w-5/12 rounded-lg outline-none border border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
                    placeholder="City"
                    required
                    pattern="^[A-Za-z]+(?:[\s-][A-Za-z]+)*$"
                    value={formData.billing.city}
                    onChange={(e) =>
                      handleChange("billing", "city", e.target.value)
                    }
                  />
                  <input
                    type="text"
                    className="bg-white h-[65px] w-5/12 rounded-lg outline-none border border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
                    placeholder="State"
                    required
                    maxLength={2}
                    pattern="^[A-Za-z]{2}$"
                    value={formData.billing.state}
                    onChange={(e) =>
                      handleChange("billing", "state", e.target.value)
                    }
                  />
                  <input
                    type="text"
                    className="bg-white h-[65px] w-5/12 rounded-lg outline-none border border-primary-red px-3 text-sm placeholder:text-primary-red placeholder:font-medium"
                    placeholder="ZIP Code"
                    required
                    pattern="^\d{5}(-\d{4})?$"
                    value={formData.billing.zipCode}
                    onChange={(e) =>
                      handleChange("billing", "zipCode", e.target.value)
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {/* <button
        type="submit"
        className="mt-6 bg-primary-red text-white px-4 py-2 rounded hover:bg-red-700"
      >
        Submit
      </button> */}
        <button
          type="submit"
          // onClick={handleSubmit}
          className={`absolute bottom-14 max-sm:bottom-6 w-[45%] py-2 rounded-[49px] text-white ${
            true ? "bg-primary-red" : "bg-gray-300"
          } `}
          // onClick={() => setCartPage(2)}
          disabled={false}
        >
          CHECKOUT
        </button>
      </form>
    );
  }

  return (
    <div
      className={`w-screen absolute top-0 right-0 flex justify-center items-center !overflow-hidden z-[100] ${
        isCartOpen ? "h-screen" : " !hidden"
      }`}
    >
      <AnimatePresence>
        {isCartOpen && (
          <motion.div
            initial={{ x: "100%", opacity: 1 }}
            animate={{ x: 0, scale: 1, opacity: 1 }}
            exit={{ x: "100%", opacity: 1 }}
            transition={{ type: "tween", duration: 0.3 }}
            className=" fixed w-full h-[100%] py-8 md:pt-[75px] flex max-sm:justify-center bg-transparent justify-end md:mr-4 items-center text-primary-red z-[70] "
          >
            <motion.div className="max-sm:w-[94%] w-[400px] h-[100%] bg-white border-2 rounded-[49px] flex flex-col items-center pt-24 font-bold z-[1000] relative border-primary-red">
              <div
                onClick={() => (
                  setCartPage(0), AppStore.setState({ isCartOpen: false })
                )}
                className="absolute top-5 hover:cursor-pointer"
              >
                <svg width="28px" height="22px" viewBox="0 0 1024 1024">
                  <path
                    fill="#c62625"
                    d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                  />
                </svg>
              </div>
              {cartPage == 0 && (
                <div className="w-full h-full flex flex-col items-center p-6">
                  <div className="flex w-full h-[45px] justify-between items-center border-t-2 border-b-2 border-primary-red px-2">
                    <img
                      src={simpleComb}
                      alt="cmb-small"
                      className="w-12 h-12"
                    />
                    <h5 className="min-w-[30px]">{cartCount}x</h5>
                    <h4>BEAN POCKET COMB</h4>
                    <h4>$40</h4>
                  </div>
                  <div className="w-full flex justify-between px-0 py-3">
                    <span className="text-gray-400 text-sm">EDIT CART</span>
                    <div className="flex h-[20px] w-[65px] gap-1 text-white">
                      <button
                        className="w-full h-full text-center items-center flex justify-center pb-[3px] rounded-l-[49px] bg-gray-300"
                        onClick={() => decreaseCart()}
                      >
                        -
                      </button>
                      <button
                        className="w-full h-full text-center items-center flex justify-center pb-[3px] rounded-r-[49px] bg-gray-300"
                        onClick={() => increaseCart()}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="w-full flex justify-start mt-2">
                    <h4 className="w-full">+ $7 SHIPPING</h4>
                    <div className="w-full flex justify-between">
                      <h4 className=" ">SUBTOTAL</h4>
                      <h4 className=" ">${totalPrice}</h4>
                    </div>
                  </div>
                  <button
                    className={`absolute bottom-14 max-sm:bottom-6 w-[45%] py-2 rounded-[49px] text-white ${
                      cartCount > 0 ? "bg-primary-red" : "bg-gray-300"
                    } `}
                    onClick={() => setCartPage(1)}
                    disabled={cartCount > 0 ? false : true}
                  >
                    CHECKOUT
                  </button>
                </div>
              )}
              {cartPage == 1 && (
                <div className="w-full h-full flex flex-col items-center px-8 overflow-y-scroll pb-24">
                  {/* FILLING FORM */}
                  <PreCheckoutForm />
                </div>
              )}
              {cartPage == 2 && (
                <div className="w-full h-full flex flex-col items-center">
                  {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm
                        setCartPage={setCartPage}
                        formData={finalFormData}
                        paymentIntent={paymentIntent}
                      />
                    </Elements>
                  )}
                </div>
              )}

              {cartPage == 3 && (
                <div className="w-full h-full flex flex-col items-center justify-center relative text-center px-8 ">
                  <h3>Thank you for your purchase.</h3>
                  <h3>
                    You'll receive an email with order confirmation shortly
                  </h3>
                  <button
                    className={`absolute bottom-14 max-sm:bottom-6 w-[45%] py-2 rounded-[49px] text-white bg-primary-red`}
                    onClick={handleCompleteOrder}
                  >
                    COMPLETE
                  </button>
                </div>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Cart;
