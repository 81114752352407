// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Slider.css */
.slider-container {
  /* position: relative; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.dots {
  /* width: 100px;
  height: 20px; */
  position: absolute;
  bottom: 20px;
  left: 49%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 0;
}

.dot {
  width: 15px;
  height: 15px;
  background-color: rgba(180, 180, 180, 0.8);
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #c62625;
}

/* Media query for dots on mobile */
@media (max-width: 768px) {
  .dots {
    right: 10px;
    left: 95%;
    top: 39%;
    flex-direction: column;
  }

  .dot {
    width: 10px;
    height: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Slider.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,wBAAwB;EACxB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE;iBACe;EACf,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,aAAa;EACb,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,0CAA0C;EAC1C,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,mCAAmC;AACnC;EACE;IACE,WAAW;IACX,SAAS;IACT,QAAQ;IACR,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":["/* src/Slider.css */\r\n.slider-container {\r\n  /* position: relative; */\r\n  width: 100%;\r\n  height: 100%;\r\n  overflow: hidden;\r\n}\r\n\r\n.slider-background {\r\n  position: absolute;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  background-position: center;\r\n}\r\n\r\n.dots {\r\n  /* width: 100px;\r\n  height: 20px; */\r\n  position: absolute;\r\n  bottom: 20px;\r\n  left: 49%;\r\n  transform: translateX(-50%);\r\n  display: flex;\r\n  gap: 10px;\r\n  z-index: 0;\r\n}\r\n\r\n.dot {\r\n  width: 15px;\r\n  height: 15px;\r\n  background-color: rgba(180, 180, 180, 0.8);\r\n  border-radius: 50%;\r\n  cursor: pointer;\r\n}\r\n\r\n.dot.active {\r\n  background-color: #c62625;\r\n}\r\n\r\n/* Media query for dots on mobile */\r\n@media (max-width: 768px) {\r\n  .dots {\r\n    right: 10px;\r\n    left: 95%;\r\n    top: 39%;\r\n    flex-direction: column;\r\n  }\r\n\r\n  .dot {\r\n    width: 10px;\r\n    height: 10px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
