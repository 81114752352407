// src/components/Footer.js

import React, { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AppStore } from "../constants/Stores";
import { useNavigate } from "react-router-dom";
import "./Footer.css";

const mobVariants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -20 },
};

const deskVariants = {
  visible: { y: 0 },
  hidden: { y: 100 },
};

const Footer = () => {
  const navigate = useNavigate();
  const [subEmail, setSubEmail] = useState("");
  const [status, setStatus] = useState("idle");
  const [isFooterOpen, setIsFooterOpen] = useState(false);
  const sendNotif = AppStore((state) => state.notify);
  const isMobile = AppStore((state) => state.isMobile);
  const toggleCare = AppStore((state) => state.toggleCare);
  const subInputRef = useRef(null);

  const handleToggleFooter = () => {
    setIsFooterOpen(!isFooterOpen);
  };

  const handleSubscribe = async () => {
    if (!subEmail) {
      sendNotif("Enter email address.");
      return;
    }

    // Simple email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(subEmail)) {
      sendNotif("Please enter a valid email address.");
      return;
    }

    try {
      setStatus("loading");
      const res = await fetch(`${AppStore.getState().bUrl}/subscribe-news`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: subEmail }),
      });

      if (res.ok) {
        setStatus("success");
        sendNotif("Subscribed.");
        setSubEmail("");
        setTimeout(() => {
          setStatus("idle");
        }, 1500);
      } else {
        setStatus("idle");
        sendNotif("Error occurred while subscribing.");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setStatus("idle");
      sendNotif("Error occurred while subscribing.");
    }
  };

  useEffect(() => {
    if (isFooterOpen && subInputRef.current) {
      subInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [isFooterOpen]);

  return (
    <div
      id="foot"
      className={`${
        isMobile ? "relative" : "fixed"
      } w-full bottom-0 left-0 z-[100]`}
    >
      <AnimatePresence>
      <div
      key={'footer-action-buttons'}
        className={`flex w-full h-10 ${
          isFooterOpen ? "absolute" : ""
        } ${isMobile ? "justify-center" : "px-4 justify-between mb-1"} text-primary-red text-4xl z-[50]`}
      >
        <button
          onClick={handleToggleFooter}
          className="hover:cursor-pointer focus:outline-none bg-transparent"
        >
          {isFooterOpen ? "-" : "+"}
        </button>
        {!isMobile && (
          <button
            onClick={handleToggleFooter}
            className="hover:cursor-pointer focus:outline-none bg-transparent"
          >
            {isFooterOpen ? "-" : "+"}
          </button>
        )}
      </div>
      {/* FOOTER CONTENT */}
        {isFooterOpen && (
          <motion.div
            className="max-sm:rounded-[49px] overflow-hidden max-sm:mt-4 max-sm:border-2 border-2 rounded-t-lg border-[#C62625]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // transition={{ duration: 0.15 }}
          >
            <motion.div
              variants={isMobile ? mobVariants : deskVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
              className={`${
                isFooterOpen ? "flex" : "hidden"
              } w-full min-h-[170px] bottom-0`}
              style={{ background: "white" }}
            >
              <div
                className={
                  isMobile
                    ? "grid w-full min-h-full justify-evenly pb-10 pt-4 text-md text-primary-red px-6"
                    : "flex w-full min-h-full justify-evenly pb-2 pt-4 text-md text-primary-red px-6"
                }
              >
                <div className="flex w-full gap-8 min-h-full md:justify-around max-sm:justify-between pb-2 text-sm font-bold">
                  {/* NEWSLETTER */}
                  {!isMobile && (
                    <div className="flex flex-col mb-4 h-full justify-between font-bold">
                      <h2 className="text-xl font-medium">Newsletter</h2>
                      <div className="flex gap-5 border-b py-1 mb-3 items-center">
                        <svg
                          width="19px"
                          height="19px"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.3153 16.6681C15.9247 17.0587 15.9247 17.6918 16.3153 18.0824C16.7058 18.4729 17.339 18.4729 17.7295 18.0824L22.3951 13.4168C23.1761 12.6357 23.1761 11.3694 22.3951 10.5883L17.7266 5.9199C17.3361 5.52938 16.703 5.52938 16.3124 5.91991C15.9219 6.31043 15.9219 6.9436 16.3124 7.33412L19.9785 11.0002L2 11.0002C1.44772 11.0002 1 11.4479 1 12.0002C1 12.5524 1.44772 13.0002 2 13.0002L19.9832 13.0002L16.3153 16.6681Z"
                            fill="#C62625"
                          />
                        </svg>
                        <input
                          ref={subInputRef}
                          value={subEmail}
                          placeholder="Enter your email address"
                          className="bg-transparent outline-none placeholder:text-primary-red opacity-80 text-primary-red"
                          onChange={(e) => setSubEmail(e.target.value)}
                        />
                        <button
                          className={`subscribe-button ${status}`}
                          onClick={handleSubscribe}
                          disabled={status === "loading"}
                        >
                          {status === "loading"
                            ? "Loading..."
                            : status === "success"
                            ? "Subscribed"
                            : "Subscribe"}
                        </button>
                      </div>
                    </div>
                  )}
                  {/* LINKS */}
                  <div className="flex flex-col mb-2 h-full justify-between font-bold">
                    <h2 className="text-xl ml-[-5px]">Links</h2>
                    <div className="grid font-medium text-sm max-sm:gap-1">
                      <button
                        className="hover:underline hover:cursor-pointer text-left"
                        onClick={() => navigate("/shop")}
                      >
                        SHOP
                      </button>
                      <button
                        className="hover:underline hover:cursor-pointer text-left"
                        onClick={() => navigate("/about")}
                      >
                        ABOUT
                      </button>
                      <button
                        className="hover:underline hover:cursor-pointer text-left"
                        onClick={toggleCare}
                      >
                        CARE
                      </button>
                    </div>
                  </div>
                  {/* CONTACT */}
                  {!isMobile && (
                    <div className="flex flex-col mb-2 h-full justify-between font-bold">
                      <h2 className="text-xl ml-[-5px]">Contact</h2>
                      <div className="grid font-medium">
                        <a
                          href="mailto:info@orima.world"
                          className="hover:underline hover:cursor-pointer"
                        >
                          INFO@ORIMA.WORLD
                        </a>
                      </div>
                    </div>
                  )}
                  {/* SOCIALS */}
                  {!isMobile && (
                    <div className="flex flex-col h-full justify-between font-bold mt-2">
                      <h2 className="text-xl ml-[-5px]">Follow</h2>
                      <div className="grid font-medium">
                        <a
                          className="hover:underline text-sm hover:cursor-pointer"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.instagram.com/orima.world"
                        >
                          INSTAGRAM
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                {/* CONTACT Mobile */}
                {isMobile && (
                  <div className="flex flex-col mb-1 h-full justify-between py-2 font-bold ">
                    <h2 className="text-xl ml-[-5px]">Contact</h2>
                    <div className="grid font-medium">
                      <a
                        href="mailto:info@orima.world"
                        className="hover:underline hover:cursor-pointer text-sm"
                      >
                        INFO@ORIMA.WORLD
                      </a>
                    </div>
                  </div>
                )}
                {/* SOCIALS Mobile */}
                {isMobile && (
                  <div className="flex flex-col h-full justify-between font-bold mt-2 ">
                    <h2 className="text-xl ml-[-5px]">Follow</h2>
                    <div className="grid font-medium">
                      <a
                        className="hover:underline text-sm hover:cursor-pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/orima.world"
                      >
                        INSTAGRAM
                      </a>
                    </div>
                  </div>
                )}
                {/* NEWSLETTER MOBILE */}
                {isMobile && (
                  <div className="flex flex-col mb-4 h-full justify-center mt-2 ">
                    <h2 className="text-xl font-bold ml-[-5px] mb-1">
                      Newsletter
                    </h2>
                    <div className="flex gap-5 justify-between border-b border-primary-red py-1 mb-3 items-center max-w-full">
                      <svg
                        width="19px"
                        height="19px"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.3153 16.6681C15.9247 17.0587 15.9247 17.6918 16.3153 18.0824C16.7058 18.4729 17.339 18.4729 17.7295 18.0824L22.3951 13.4168C23.1761 12.6357 23.1761 11.3694 22.3951 10.5883L17.7266 5.9199C17.3361 5.52938 16.703 5.52938 16.3124 5.91991C15.9219 6.31043 15.9219 6.9436 16.3124 7.33412L19.9785 11.0002L2 11.0002C1.44772 11.0002 1 11.4479 1 12.0002C1 12.5524 1.44772 13.0002 2 13.0002L19.9832 13.0002L16.3153 16.6681Z"
                          fill="#C62625"
                        />
                      </svg>
                      <input
                        ref={subInputRef}
                        value={subEmail}
                        placeholder="Your email address"
                        className="bg-transparent outline-none placeholder:text-primary-red opacity-80 text-primary-red"
                        onChange={(e) => setSubEmail(e.target.value)}
                      />
                      <button
                        className={`subscribe-button ${status}`}
                        onClick={handleSubscribe}
                        disabled={status === "loading"}
                      >
                        {status === "loading"
                          ? "Loading..."
                          : status === "success"
                          ? "Subscribed"
                          : "Subscribe"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Footer;
